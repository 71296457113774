import { useLayoutEffect, useMemo } from "react";
import { Button, Form, notification, Tabs, Typography } from "antd";
import { useParams } from "react-router-dom";

import { IPS_TRANS_FEE_ENABLED, SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings, useTabs } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { Alert, CustomFormWrapper, FeesValuesRow, HeadingsRow, NetworkForm, UnsavedChangesModal } from "@ni/common/ui";
import { getErrorInstance, getFormValueFromProductValues, parseBooleanOrNumber } from "@ni/common/utils";

import { usePct } from "../../../../hooks";
import { TabKeysCTF } from "../../../../types";

import { CARD_TRANSACTION_FEES_TABS, CTF_DEPENDED_VALUES, CTF_HEADINGS, EMTY_CTF_VALUES } from "./constants";
import { createKey, useCardTransactionFees } from "./useCardTransactionFees";

import styles from "../../styles.module.scss";

export const CardTransactionFeesPage = () => {
  const [form] = Form.useForm<FormValues>();
  const ipsTransFeeEnabled = Form.useWatch<string>(IPS_TRANS_FEE_ENABLED, form);

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [tabs, activeTab, setActiveTab, changesChecker] = useTabs<TabKeysCTF>(CARD_TRANSACTION_FEES_TABS, {
    isCheckEnabled: true,
    form,
    excludedFieldsListFromCheck: [IPS_TRANS_FEE_ENABLED],
    initialValues: form.getFieldsValue(true) as FormValues,
  });

  const {
    nextPage = {
      name: "",
      key: "",
    },
    isOpen,
    disabled,
    onDiscardChanges = (key: TabKeysCTF) => {
      return key;
    },
    onCloseModal,
  } = changesChecker || {};

  const alternativeActiveKey = useMemo(() => createKey(activeTab.key, false, true), [activeTab.key]);

  const regEnableCode = useMemo(() => `${alternativeActiveKey}-reg-enable`, [alternativeActiveKey]);
  const intraregEnableCode = useMemo(() => `${alternativeActiveKey}-intrareg-enable`, [alternativeActiveKey]);
  const amountEnableCode = useMemo(() => `${alternativeActiveKey}-amount-enable`, [alternativeActiveKey]);

  const regEnabled = Form.useWatch<boolean>(regEnableCode, form);
  const interaregEnabled = Form.useWatch<boolean>(intraregEnableCode, form);
  const amountEnabled = Form.useWatch<boolean>(amountEnableCode, form);

  const { fields, withAmountSwitch, getEmptyValuesByKey } = useCardTransactionFees(activeTab.key, {
    reg: regEnabled,
    amount: amountEnabled,
    intrareg: interaregEnabled,
    productValues: pct.pctProductValues ?? [],
  });

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  useLayoutEffect(() => {
    const isIpsTransEnabled = parseBooleanOrNumber(
      getFormValueFromProductValues(pct.pctProductValues, IPS_TRANS_FEE_ENABLED) as string,
    ) as boolean;

    if (isIpsTransEnabled) {
      form.setFieldValue(IPS_TRANS_FEE_ENABLED, isIpsTransEnabled);
    }

    const currentSectionValues = pct.pctProductValues?.filter(
      item =>
        ((item.fieldCode.includes(activeTab.key) || item.fieldCode.includes(alternativeActiveKey)) &&
          item.fieldCode.includes("ips") &&
          !item.fieldCode.includes("def-tier-min")) ||
        item.fieldCode === regEnableCode ||
        item.fieldCode === amountEnableCode ||
        item.fieldCode === intraregEnableCode,
    );

    (currentSectionValues ?? []).forEach(pctValue => {
      if (pctValue.fieldCode === IPS_TRANS_FEE_ENABLED && form.getFieldValue(pctValue.fieldCode)) {
        return;
      }

      form.setFieldValue(pctValue.fieldCode, parseBooleanOrNumber(pctValue.value ?? ""));
    });
  }, [
    alternativeActiveKey,
    activeTab.key,
    form,
    pct.pctProductValues,
    regEnableCode,
    amountEnableCode,
    intraregEnableCode,
  ]);

  const onFinish = async (values: FormValues, isControlledFromModal = false): Promise<void> => {
    try {
      const collectedValues = ipsTransFeeEnabled
        ? ({
            ...values,
            ...(!regEnabled && getEmptyValuesByKey(activeTab.key, "reg")),
            ...(!amountEnabled && getEmptyValuesByKey(activeTab.key, "amount")),
            ...(!interaregEnabled && getEmptyValuesByKey(activeTab.key, "intrareg")),
          } as FormValues)
        : EMTY_CTF_VALUES;

      await onSavePct(collectedValues as FormValues).then(() => {
        form.resetFields(
          pct.pctProductValues
            ?.filter(pctValue => pctValue.fieldCode.includes("def-tier-min"))
            .map(field => field.fieldCode),
        );

        CTF_DEPENDED_VALUES.forEach(values => {
          form.setFieldValue(values.child, form.getFieldValue(values.parent));
        });
      });

      if (isControlledFromModal) {
        onDiscardChanges(nextPage.key, true);
      }

      if (!isControlledFromModal) {
        form.setFields(
          Object.entries(form.getFieldsValue()).map(field => ({
            name: field[0],
            value: field[1],
            touched: false,
          })),
        );
      }
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className="ni-tabs">
          <Tabs
            defaultActiveKey={tabs[0].key}
            activeKey={activeTab.key}
            items={tabs}
            onChange={key => setActiveTab(key as TabKeysCTF)}
          />
        </div>

        <div className={styles["fee-switch-group"]}>
          <NetworkForm.Switch
            formItemOptions={{
              name: regEnableCode,
              label: "Differentiate fee for Onus & domestic",
              tooltip:
                "Tooltip: This parameter enables segregating fees for onus and domestic transaction types, domestic transaction countries are identified in FI level fee settings",
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              name: intraregEnableCode,
              label: "Differentiate fee for intraregional area",
              tooltip:
                "Tooltip: This parameter enables segregating fees for transactions originating from intraregional region as identified in FI level fee settings",
            }}
          />

          {withAmountSwitch && (
            <NetworkForm.Switch
              formItemOptions={{
                name: amountEnableCode,
                label: "Differentiate the fee by transaction amount",
                tooltip:
                  "It is possible to charge the separate fee for the transaction amount falling in a particular tier. For example: If the transaction amount is between 0 and 1000 AED, then 3% of transaction amount is charged on the account. If the transaction amount is above 1000 AED, then 1% of transaction amount is charged on the account.",
              }}
            />
          )}
        </div>

        <div className={styles["pct-editing-box"]}>
          <HeadingsRow firstColSpan={4} headings={amountEnabled ? CTF_HEADINGS : CTF_HEADINGS.slice(2, 6)} />

          {fields.additionalFee && (
            <FeesValuesRow
              rowLabel="Additional tier fee"
              rowTooltip="The fee is applied to the transaction amount between values defined in Min transaction amount (including) and Max transaction amount (not including). "
              productCurrency={productCurrency}
              form={form}
              fields={fields.additionalFee}
            />
          )}

          {fields.defaultFee && (
            <FeesValuesRow
              rowLabel={amountEnabled ? "Default tier fee" : "Default fee"}
              rowTooltip={
                regEnabled && amountEnabled
                  ? "The fee is applied to any transaction amount beyond the range that is defined in additional tier, unless it is redefined by values below for specific cases (OnUs/ Domestic transactions)"
                  : amountEnabled
                    ? "The fee is applied to any transaction amount beyond the range that is defined in additional tier"
                    : "The fee is applied to the transaction type unless it is redefined by values below for specific cases (OnUs/ Domestic)"
              }
              productCurrency={productCurrency}
              form={form}
              fields={fields.defaultFee}
            />
          )}

          {fields.onUsAdditionalFee && (
            <FeesValuesRow
              rowLabel="OnUs additional tier fee"
              rowTooltip="The fee is applied to the OnUs transactions with amount between values defined in Min transaction amount (including) and Max transaction amount (not including)"
              productCurrency={productCurrency}
              form={form}
              fields={fields.onUsAdditionalFee}
            />
          )}

          {fields.onUsFee && (
            <FeesValuesRow
              rowLabel={amountEnabled ? "OnUs default tier fee" : "OnUs fee"}
              rowTooltip="For transactions done through an issuer device network (card issuer and the acquirer are the same bank)"
              productCurrency={productCurrency}
              form={form}
              fields={fields.onUsFee}
            />
          )}

          {fields.domesticAdditionalFee && (
            <FeesValuesRow
              rowLabel="Domestic additional tier fee"
              rowTooltip="The fee is applied to the domestic transaction with amount between values defined in Min transaction amount (including) and Max transaction amount (not including)"
              productCurrency={productCurrency}
              form={form}
              fields={fields.domesticAdditionalFee}
            />
          )}

          {fields.domesticFee && (
            <FeesValuesRow
              rowLabel={amountEnabled ? "Domestic default tier fee" : "Domestic fee"}
              rowTooltip="For transaction done through device located on the domestic country (excluding OnUs transactions, if specified)"
              productCurrency={productCurrency}
              form={form}
              fields={fields.domesticFee}
            />
          )}

          {fields.intraregionalFee && (
            <FeesValuesRow
              rowLabel={amountEnabled ? "Intraregional default tier fee" : "Intraregional fee"}
              productCurrency={productCurrency}
              form={form}
              fields={fields.intraregionalFee}
            />
          )}

          {fields.intraregionalAdditionalFee && (
            <FeesValuesRow
              rowLabel="Additional intraregional  tier fee"
              productCurrency={productCurrency}
              form={form}
              fields={fields.intraregionalAdditionalFee}
            />
          )}

          {regEnabled && (
            <Alert>
              Please reach out to your Network International representative for providing your acquiring process details
              to segregate OnUs transactions
            </Alert>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <CustomFormWrapper
        form={form}
        pageTitle="Card Transaction Fees"
        pageSubtitle="Transaction Based Fees are charged to an account only when certain types of transactions occur on an account."
        submitHandler={onFinish}
        size="full"
        formSize="full"
        level="pct"
        submitLabel="Save"
        relatedLinks={relatedLinks}
        onValuesChange={form.validateFields}
      >
        <div className={styles["fee-switch-group"]}>
          <NetworkForm.Switch
            formItemOptions={{
              name: "ips-trans-fee-enabled",
              label: <Typography.Text strong={true}>Enable card transaction fees on your product</Typography.Text>,
              valuePropName: "checked",
            }}
          />

          {ipsTransFeeEnabled && (
            <NetworkForm.Switch
              formItemOptions={{
                name: "nic-ips-fees-fin-only",
                label: <Typography.Text strong={true}>Do not block fee amount on authorization</Typography.Text>,
                valuePropName: "checked",
              }}
            />
          )}
        </div>

        {ipsTransFeeEnabled && renderContent()}
      </CustomFormWrapper>

      <UnsavedChangesModal
        disabled={disabled}
        isOpen={!!isOpen}
        footer={[
          <Button key="discard" onClick={() => onDiscardChanges(nextPage.key, true)}>
            Discard
          </Button>,
          <Button key="stay" type={disabled ? "primary" : "default"} onClick={onCloseModal}>
            Stay
          </Button>,
          !disabled ? (
            <Button key="Save" danger={true} type="primary" onClick={() => onFinish(form.getFieldsValue(), true)}>
              Save
            </Button>
          ) : null,
        ]}
      />
    </>
  );
};
